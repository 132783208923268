<template>
  <div class="content">
    <sub-title>슬롯</sub-title>
    <div class="casinoslot_panel">
      <money-chip-change-comp></money-chip-change-comp>
      <div class="slots_panel">
        <div class="search">
          <div class="s1" @click="goback"><i class="fa fa-outdent"></i></div>
          <div class="s2">{{ currentSlotCode }}</div>
          <div class="s3">
            <input type="text" v-model="searchVal" placeholder="슬롯제목 입력" @keyup.enter="searchSlotTitle">
            <button @click="searchSlotTitle">검색</button>
          </div>
        </div>
      </div>
      <div class="items">
        <div class="slotitem"  v-for="(item,index) in slotGameList" :key="index"
             @click="openGame(item.id,item.vendor,4)" v-if="item.isshow">
          <div class="pic"><img :src="item.thumbnail" alt=""></div>
          <div class="tit">{{item.langs['ko']}}</div>
        </div>
      </div>
    </div>
  </div>

</template>


<script>

    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "@/store/mutation-types";
    import {casinoMixin, postionMixin} from "@/common/mixin";
    import sportsConst from "@/common/sportsConst";
    import SubTitle from "@/views/afront/zero/SubTitle.vue";
    import MoneyChipChangeComp from "@/views/afront/casino/MoneyChipChangeComp.vue";
    import {getCasinoMaintaining} from "@/network/casinoHonorRequest";
    export default {
      name: "SlotHonor",
      mixins: [postionMixin,casinoMixin],
      components: {
        MoneyChipChangeComp,
        SubTitle

      },
      data() {
        return {
          position: '슬롯',
          searchVal:'',
        }
      },
      methods: {
        goback() {
          this.$router.go(-1)
        },
        searchSlotTitle() {
          console.log(this.searchVal.trim())
          if (this.searchVal.trim() === '') {
            this.slotGameList.forEach(g => {
              this.$set(g, 'isshow', true)
            });
          } else {

            this.slotGameList.forEach(g => {
              console.log(g['langs'].ko)
              if (g['langs'].ko.indexOf(this.searchVal.trim()) !== -1) {
                this.$set(g, 'isshow', true)
              } else {
                this.$set(g, 'isshow', false)
              }

            });
          }
        },
      },
      created() {
        this.currentSlotCode = this.$route.query.slotcode;
        if (this.$store.state.isLogin) {
          getCasinoMaintaining().then(res => {
            if (res.data.success) {
              if (!res.data.data) {
                this.$swal({
                  title: '카지노 점검중입니다',
                  type: 'error',
                  confirmButtonText: '확인',
                  showCancelButton: false,
                  showConfirmButton: true
                })
              }else {
                this.getGameListByCode(this.currentSlotCode)
              }
            } else {
              this.$swal({
                title: res.data.msg,
                type: 'error',
                showCancelButton: false,
                showConfirmButton: true
              })
            }

          })
        }
      },
    }
</script>

<style scoped>
    @import url("../../../assets/css/front/afrontcasinoslot.css");
</style>